<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="11" md="8">
        <v-text-field
            label="Search for badges by name, description, or tag"
            placeholder=""
            prepend-inner-icon="mdi-magnify"
            outlined
            clearable
            v-model="search"
            :loading="isLoading"
          >
          </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="badges.length > 0">
      <v-col cols="12" md="4" lg="3" v-for="(item, index) in badges" :key="index">
         <v-card
            @click="routeToBadge(item.name)"
            :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            class="ma-2 pa-1"
            outlined
            hover
            ripple
          >
            <v-img
              contain
              max-height="150"
              style="height: 150px"
              :transition="false"
              :src="item.imageBase64"
              :lazy-src="badgeLoadingImage"
              class="ma-2 pt-2"
            ></v-img>

            <v-card-subtitle class="d-flex justify-center">{{ item.displayName }}</v-card-subtitle>
            <v-card-text>
              <v-chip class="ma-1" small v-for="(chip, chipIndex) in item.tags" :key="'chip' + chipIndex">
                {{ chip }}
              </v-chip>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <transition name="scroll-y-transition">
                <v-btn  v-if="editModeEnabled" key="card-actions" @click.stop="displayDeleteDialog(item.id)" :disaled="isDeleting" icon><v-icon>mdi-delete</v-icon></v-btn>
              </transition>
            </v-card-actions>
         </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <span>No badges were found for this group</span>
      </v-col>
    </v-row>
    <v-row v-if="count > 1">
      <v-col>
        <v-pagination
          v-model="page"
          :length="count"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ListBadgeComponent',
  props: {
    group: {
      required: true
    },
    editModeEnabled: {
      required: true
    }
  },
  watch: {
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getBadges()
      }, 500)
    },
    page: function () {
      this.getBadges()
    }
  },
  data: () => ({
    isDeleting: false,
    badgeLoadingImage: require('@/assets/defaultBadge.svg'),
    badges: [ ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    searchTimeout: null
  }),
  methods: {
    routeToBadge: function (badgeName) {
      this.$router.push(`/badges/details/${badgeName}`)
    },
    getBadges: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.group.id}/badges?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.badges = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)

            this.badges.forEach(x => {
              this.getImage(x)
            })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getBadges() })
          })
    },
    getImage: function (badge) {
      if (badge.image) {
        return axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/images/${badge.image.id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              badge.imageBase64 = response.data.base64Image
              this.$forceUpdate()
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
            })
      }
    },
    displayDeleteDialog: function (badgeId) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Badge? This action can not be undone. Issued badges to users will not be removed',
        confirmMethod: () => {
          this.deleteBadge(badgeId)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteBadge: function (badgeId) {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${badgeId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.badges = this.badges.filter(x => x.id !== badgeId)
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteBadge(badgeId) })
          })
    }
  },
  mounted: function () {
    this.getBadges()
  }
}
</script>

<style scoped>

</style>
