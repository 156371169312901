<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Group Badges
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showCreateBadgeDialog = true" v-if="hasAdminRole">Create Badge</v-btn>
          </v-card-title>
          <v-card-subtitle>
            Badges displayed below are owned by {{ group.displayName }}.They can be issued to or revoked from employees by selecting the badge from the list below.
          </v-card-subtitle>
          <v-card-text>
            <v-divider />
            <list-badge-component :group="group" :editModeEnabled="editModeEnabled" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <new-badge-component :group="group" :showDialog="showCreateBadgeDialog" @dialogEvent="handleDialogEvent" />
  </v-container>
</template>

<script>
import listBadgeComponent from './listBadgeComponent'
import newBadgeComponent from './newBadgeComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'GroupBadgesComponent',
  props: {
    group: {
      required: true
    },
    editModeEnabled: {
      required: true
    }
  },
  components: {
    'new-badge-component': newBadgeComponent,
    'list-badge-component': listBadgeComponent
  },
  computed: {
    ...mapGetters([
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined || (this.group && this.group.users.find(x => x.userId.toLowerCase() === this.badgeUser.userId.toLowerCase()) !== undefined)
      }
    }
  },
  data: () => ({
    showCreateBadgeDialog: false
  }),
  methods: {
    handleDialogEvent: function () {
      this.showCreateBadgeDialog = false
    }
  },
  beforeMount: function () {
  }
}
</script>

<style scoped>

</style>
