<template>
  <v-container>
    <v-form ref="form">
    <v-row>
      <v-col cols="12" md="8" sm="6" class="d-flex justify-start align-center">
        <v-skeleton-loader
          v-if="!group || !group.image || !group.image.base64Image"
          class="ma-2"
          style="height: 90px; width: 90px;"
          type="image"
        ></v-skeleton-loader>
        <transition name="fade-transition" mode="out-in">
          <v-tooltip bottom v-if="group && group.image && group.image.base64Image && editModeEnabled" key="edit-image-enabled">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                offset-x="10"
                offset-y="20"
                color="primary"
                icon="mdi-pencil"
                overlap
              >
              <v-img
                class="image-select mb-1"
                @click="showUpdateImageDialog"
                max-height="100"
                max-width="100"
                min-height="100"
                min-width="100"
                :src="group.image.base64Image"
                contain
                style="width: 100px"
                v-bind="attrs"
                v-on="on"
              />
              </v-badge>
              </template>
            <span>Click to edit</span>
          </v-tooltip>
          <v-img
            key="edit-image"
            v-if="group && group.image && group.image.base64Image && !editModeEnabled"
            class="mb-1"
            max-height="100"
            max-width="100"
            min-height="100"
            min-width="100"
            :src="group.image.base64Image"
            contain
            style="width: 100px"
          />
        </transition>
        <v-skeleton-loader
          v-if="!group"
          type="list-item-two-line"
          class="badge-name-loading"
          style="width: 500px"
        ></v-skeleton-loader>
        <transition name="slide-y-transition" mode="out-in">
        <span v-if="group && !editModeEnabled" class="ma-2 text-h4" key="edit-display-off">{{ group.displayName }}</span>
          <v-text-field
            key="edit-displayName"
            solo
            style="margin-top:25px;"
            v-if="group && editModeEnabled"
            class="ml-2"
            v-model="group.displayName"
            label="Name"
            hint="The name field is provided with the badges to indicate who issued it"
            persistent-hint
          />
        </transition>
      </v-col>
      <v-spacer></v-spacer>
      <transition name="slide-y-transition" mode="out-in">
        <v-col cols="12" md="3" class="d-flex justify-center align-center" v-if="!editModeEnabled" key="edit-mode-disabled">
          <v-btn class="ma-1" color="primary" @click="editModeEnabled = true" v-if="hasAdminRole">Edit Group</v-btn>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center" v-if="editModeEnabled" key="edit-mode-enabled">
          <div>
          <v-btn class="ma-1" color="error" :loading="isDeleting" :disabled="isDeleting" @click="displayDeleteDialog" style="width: 130px">Delete</v-btn>
          <br/>
          <v-btn class="ma-1" :loading="isSubmitted" :disabled="isSubmitted" @click="updateGroup" style="width: 130px">Save</v-btn>
          </div>
        </v-col>
      </transition>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="12" xl="12">
      <v-tabs
        v-model="tabView"
      >
        <v-tab>Group Details</v-tab>
        <v-tab>Group Badges</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabView">
        <v-tab-item class="no-transition">
          <div class="tab-item-wrapper">
          <v-row class="edit-component" v-if="group">
            <v-col cols="12" md="12">
              <v-card flat>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-row>
                          <v-col>
                            <span class="text-h6 pa-2">Tags:</span>
                            <transition name="slide-y-transition" mode="out-in">
                              <div v-if="!editModeEnabled" key="edit-chip-disabled" class="ml-3">
                                <v-chip
                                  v-for="(chip, index) in group.tags"
                                  :key="index"
                                  class="ma-2"
                                >
                                  {{ chip }}
                                </v-chip>
                              </div>
                              <div v-else key="edit-chip" class="ml-3">
                                <v-combobox
                                  style="max-width: 500px"
                                  solo
                                  v-model="group.tags"
                                  hint="Type a tag and press enter. Maximum of 5."
                                  :rules="tagRules"
                                  label="Tags"
                                  multiple
                                  persistent-hint
                                  small-chips
                                >
                                </v-combobox>
                              </div>
                            </transition>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="text-h6 pa-2">Description:</span>
                            <transition name="slide-y-transition" mode="out-in">
                              <v-card flat v-if="!editModeEnabled" key="edit-description" class="ml-3">
                                <v-card-text>
                                  <div>
                                    <p v-html="compiledMarkdown(group.description)"></p>
                                  </div>
                                </v-card-text>
                              </v-card>
                              <v-textarea
                                class="ml-3"
                                key="edit-description-enabled"
                                style="height: 500px !important;"
                                v-else
                                solo
                                hint="The description for your group"
                                v-model="group.description"
                                label="Markdown Description"
                                :rules="descriptionRules"
                                required
                                persistent-hint
                              />
                            </transition>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider
                        vertical
                      ></v-divider>
                      <v-col cols="12" md="3">
                        <v-row>
                          <v-col>
                            <span class="text-h6 pa-2">Url:</span>
                            <transition name="slide-y-transition" mode="out-in">
                              <div class="ml-3" v-if="!editModeEnabled" key="edit-url">
                                <span v-if="group.url"><a :href="group.url" target="_blank">{{ group.url }}</a></span>
                                <span v-else>A url was not provided</span>
                              </div>
                              <v-text-field
                                key="edit-url-enabled"
                                class="ml-3"
                                v-else
                                solo
                                hint="Optional: A url to your groups website"
                                v-model="group.url"
                                label="Url"
                                persistent-hint
                              />
                            </transition>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="text-h6 pa-2">Phone:</span>
                            <transition name="slide-y-transition" mode="out-in">
                              <div class="ml-3" v-if="!editModeEnabled" key="edit-telephone">
                                <span v-if="group.telephone">{{ group.telephone }}</span>
                                <span v-else>A phone number was not provided</span>
                              </div>
                              <v-text-field
                                key="edit-telephone-enabled"
                                v-mask="'(###) ###-####'"
                                class="ml-3"
                                v-else
                                solo
                                hint="Optional: A phone number for your group"
                                v-model="group.telephone"
                                label="Phone Number"
                                persistent-hint
                              />
                            </transition>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="text-h6 pa-2">Email:</span>
                            <transition name="slide-y-transition" mode="out-in">
                              <div class="ml-3" v-if="!editModeEnabled" key="edit-email">
                                <span v-if="group.email">{{ group.email }}</span>
                                <span v-else>An email address was not provided</span>
                              </div>
                              <v-text-field
                                key="edit-email-enabled"
                                class="ml-3"
                                v-else
                                solo
                                hint="Optional: A distribution list that can receive questions about your badges"
                                v-model="group.email"
                                label="Email"
                                persistent-hint
                              />
                            </transition>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="text-h6 pa-2">Members:</span>
                            <transition name="slide-y-transition" mode="out-in">
                              <div v-if="!editModeEnabled" key="edit-users">
                                <v-list>
                                  <v-list-item
                                    v-for="(item, i) in allowedUsers"
                                    :key="i"
                                  >
                                    <v-list-item-icon class="ma-2">
                                      <v-avatar v-if="!item || !item.ThumbnailPhoto" size="30">
                                        <v-icon large>
                                          mdi-account-circle
                                        </v-icon>
                                      </v-avatar>

                                      <v-avatar v-else size="30">
                                        <img
                                          :src="'data:image/png;base64,' + item.ThumbnailPhoto"
                                          :alt="item.DisplayName"
                                        >
                                      </v-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>{{ item.FirstName }} {{ item.LastName }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </div>
                              <div v-else class="ml-3" key="edit-users-enabled">
                                <user-search-component :error="userError" class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentUserSearch" label="Group Members" />
                                  <v-simple-table>
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th class="text-left">34</th>
                                          <th class="text-left">Name</th>
                                          <th class="text-left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="user in allowedUsers" :key="user.Key">
                                          <td>{{ user.Username }}</td>
                                          <td>{{ user.DisplayName }}</td>
                                          <v-btn icon @click="deleteUser(user.Username)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                              </div>
                            </transition>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="justify-center">
            <v-col cols="12" md="2" class="ma-4">
              <v-progress-circular
                :size="150"
                color="primary"
                indeterminate
                style="margin-top:150px"
              ></v-progress-circular>
            </v-col>
          </v-row>
          </div>
        </v-tab-item>
        <v-tab-item class="no-transition"  color="#f5f5f5">
          <div class="tab-item-wrapper">
          <group-badges-component :group="group" :editModeEnabled="editModeEnabled" />
          </div>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
    </v-row>
    </v-form>
    <v-dialog
      v-model="showImageDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">Update Group Image</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="12" md="9">
                <vue-cropper
                  v-if="group && group.image && group.image.base64Image"
                  ref="cropper"
                  :aspect-ratio="5 / 5"
                  :src="group.image.base64Image"
                  :movable="true"
                  :scalable="true"
                  :cropBoxMovable="true"
                  :cropBoxResizable="false"
                  :autoCrop="true"
                  style="max-width: 300px; max-height: 300px;"
                />
                <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn color="warning" icon @click="reset"><v-icon>mdi-file-undo</v-icon></v-btn>
                <v-btn icon @click="zoom(0.2)"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="zoom(-0.2)"><v-icon>mdi-minus</v-icon></v-btn>
                <v-btn color="primary" icon @click="$refs.FileInput.click()"><v-icon>mdi-cloud-upload</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showImageDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            :loading="isSubmittingImage"
            :disabled="isSubmittingImage"
            @click="updateImage"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import userSearchComponent from '@/components/userSearchComponent'
import groupBadgesComponent from './components/groupBadgesComponent'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import marked from 'marked'
import { mapGetters } from 'vuex'

export default {
  name: 'EditGroup',
  components: {
    'user-search-component': userSearchComponent,
    'group-badges-component': groupBadgesComponent,
    VueCropper
  },
  computed: {
    ...mapGetters([
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined || (this.group && this.group.users.find(x => x.userId.toLowerCase() === this.badgeUser.userId.toLowerCase()) !== undefined)
      }
    }
  },
  watch: {
    currentUserSearch: function (newValue) {
      if (newValue) {
        if (!this.allowedUsers.find(x => x.Key === newValue.Key)) {
          this.allowedUsers.push({
            Username: newValue.Key,
            DisplayName: newValue.Value
          })
        }
      }
    }
  },
  data: () => ({
    editModeEnabled: false,
    showImageDialog: false,
    tabView: 0,
    groupName: null,
    group: null,
    isLoading: false,
    isSubmittingImage: false,
    isSubmitted: false,
    currentUserSearch: '',
    isDeleting: false,
    allowedUsers: [ ],
    validated: true,
    userError: false,
    required: [
      v => !!v || 'This field is required',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 2000) || 'must be less than 500 characters'
    ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ]
  }),
  methods: {
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    onFileSelect: function (e) {
      const file = e.target.files[0]
      this.group.image.contentType = file.type
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom: function (percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
    reset: function () {
      this.$refs.cropper.reset()
    },
    showUpdateImageDialog: function () {
      this.showImageDialog = true
    },
    getGroup: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.groupName}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.group = response.data
            this.getImage(this.group)
            this.allowedUsers = [ ]
            this.group.users.forEach(x => {
              this.getUserDetails(x).then(x => {
                if (x) {
                  this.allowedUsers.push(x)
                }
              })
            })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getGroup() })
          })
    },
    getUserDetails: function (user) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/user/${user.userId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Group? This action can not be undone and will result in removing badges under this group. Issued badges to users will not be removed',
        confirmMethod: () => {
          this.deleteGroup()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteGroup: function () {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.group.id}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.$router.push(`/badges/groups/`)
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteGroup() })
          })
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.allowedUsers.length <= 0) {
        this.userError = true
        this.validated = false
      }

      if (this.pathError) {
        this.validated = false
      }

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    deleteUser: function (username) {
      this.allowedUsers = this.allowedUsers.filter((item) => {
        return item.Username !== username
      })
    },
    updateGroup: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      if (this.allowedUsers) {
        this.group.users = [ ]
        this.allowedUsers.forEach(x => {
          this.group.users.push({
            userId: x.Username,
            roles: ['GroupOwner']
          })
        })
      }

      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.group.id}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.group),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getGroup().then(() => {
              this.isSubmitted = false
              this.editModeEnabled = false
              this.$store.commit('triggerSnack', { snackMessage: 'The group has been updated successfully', snackColor: 'success' })
            })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createGroup() })
            return error
          })
    },
    updateImage: function () {
      let croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.isSubmittingImage = true

      this.group.image.base64Image = croppedImage

      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.group.id}/image`,
        {
          method: 'POST',
          data: JSON.stringify(this.group.image),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.isSubmittingImage = false
            this.$store.commit('triggerSnack', { snackMessage: 'The image has been updated successfully', snackColor: 'success' })
            this.getGroup().then(() => {
              this.showImageDialog = false
            })
          },
          (error) => {
            this.isSubmittingImage = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateImage() })
            return error
          })
    },
    getImage: function (group) {
      if (group.image) {
        return axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/images/${group.image.id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.group.image = response.data
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
            })
      }
    }
  },
  mounted: function () {
    if (this.$route.params.name) {
      this.groupName = this.$route.params.name
      this.getGroup().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'Groups',
            exact: true,
            disabled: false,
            to: '/badges/groups'
          },
          {
            text: this.group.displayName,
            exact: true,
            disabled: false,
            to: `/badges/groups/${this.group.name}`
          }
        ])
      })
    } else {
      alert('could not find group as a name was not provided in the url')
    }
  }
}
</script>

<style scoped>
.group-name {
  padding-top: 35px;
}

.group-name-loading {
  padding-top: 15px;
}

.loading-name {
  width: 150px;
}

.edit-component {
  padding-top: 15px;
}

.no-transition {
  transition: none;
}

.image-select {
  cursor: pointer;
}

.tab-item-wrapper {
  /* vuetify sets the v-tabs__container height to 48px */
  min-height: calc(100vh - 220px)
}

</style>
