<template>
  <v-dialog
      v-model="showCreateBadgeDialog"
      width="1000"
    >

      <v-card>
        <v-card-title>
          Create a Badge
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="badgeCreatorForm">
              <v-row>
                <v-col cols="12" md="4">
                  <v-card outlined>
                    <v-card-text>
                    <v-row>
                      <v-col  class="d-flex justify-center">
                        <vue-cropper
                        ref="cropper"
                        :aspect-ratio="7 / 7"
                        :src="badge.image.base64Image"
                        :movable="true"
                        :scalable="true"
                        :cropBoxMovable="true"
                        :cropBoxResizable="false"
                        :autoCrop="true"
                        style="max-width: 250px; max-height: 250px;"
                      />
                      <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn :disabled="isDefaultImage" color="warning" icon @click="reset"><v-icon>mdi-file-undo</v-icon></v-btn>
                        <v-btn :disabled="isDefaultImage" icon @click="zoom(0.2)"><v-icon>mdi-plus</v-icon></v-btn>
                        <v-btn :disabled="isDefaultImage" icon @click="zoom(-0.2)"><v-icon>mdi-minus</v-icon></v-btn>
                        <v-btn color="primary" icon @click="$refs.FileInput.click()"><v-icon>mdi-cloud-upload</v-icon></v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col class="text-center ma-0 pa-0">
                        <span v-if="isDefaultImage" style="color: #ff5252 !important; font-size: 10pt !important;">A custom badge image is required</span>
                      </v-col>
                    </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        :rules="required"
                        v-model="badge.displayName"
                        label="Display Name"
                        persistent-hint
                        hint="The name that will be displayed when viewing this badge"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="badge.tags"
                        hint="Type a tag and press enter. Maximum of 5."
                        :rules="tagRules"
                        label="Tags"
                        multiple
                        persistent-hint
                        small-chips
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        :rules="required"
                        v-model="badge.image.imageCaption"
                        label="Alt text for the image"
                        persistent-hint
                        hint="The alternative text that will show for screen readers"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    :rules="descriptionRules"
                    v-model="badge.description"
                    label="Description for the badge"
                    persistent-hint
                    hint="A lengthy description that explains what this badge is for"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="badge.criteria"
                    :rules="descriptionRules"
                    label="Criteria used to award this badge"
                    persistent-hint
                    hint="a lengthy description of the criteria used when awarding this badge"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col class="d-flex justify-center">
            <span v-if="validationError" style="color: #ff5252 !important; font-size: 10pt !important;">There was an error with your request. Please check for errors above and resolve them before trying again</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showCreateBadgeDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            :loading="isSubmitted"
            :disabled="isSubmitted"
            @click="createBadge"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'

export default {
  name: 'NewBadgeComponent',
  props: {
    group: {
      required: true
    },
    showDialog: {
      required: true
    }
  },
  computed: {
    showCreateBadgeDialog: {
      get: function () {
        return this.showDialog
      },
      set: function () {
        this.$emit('dialogEvent', this.showDialog)
        return false
      }
    }
  },
  components: {
    VueCropper
  },
  data: () => ({
    isSubmitted: false,
    validationError: false,
    isDefaultImage: true,
    image: null,
    mimeType: '',
    selectedFile: null,
    badge: {
      image: {
        contentType: 'image/svg+xml',
        base64Image: null
      }
    },
    required: [
      v => !!v || 'This field is required',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'must be greater than 100 characters and less than 500 characters'
    ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ]
  }),
  methods: {
    onFileSelect: function (e) {
      // Get the file
      const file = e.target.files[0]

      // Check the file type
      // if (!file.type.includes('image/svg')) {
      //   this.$store.commit('showErrorDialog', { errorData: {
      //     response: {
      //       data: {
      //         errorMessage: 'Only SVG images are supported at this time'
      //       }
      //     }
      //   }, persistErrorDialog: false, retryMethod: () => this.onFileSelect() })
      //   return false
      // }

      // Set the file type
      this.badge.image.contentType = file.type

      // Check the file reader
      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        // Handle the on load event for the image
        reader.onload = (event) => {
          // Mark that a new image has loaded
          this.isDefaultImage = false
          // Replace the cropper with the new image data
          this.$refs.cropper.replace(event.target.result)
        }
        // Read in the file data
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom: function (percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
    reset: function () {
      this.$refs.cropper.reset()
    },
    validate: function () {
      let validated = true
      this.validationError = false
      if (!this.$refs.badgeCreatorForm.validate()) {
        validated = false
        this.validationError = true
      }

      if (this.isDefaultImage) {
        validated = false
        this.validationError = true
      }

      return validated
    },
    createBadge: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      // Set the new badge cropped area content
      this.badge.image.base64Image = this.$refs.cropper.getCroppedCanvas().toDataURL()

      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.group.id}/badges`,
        {
          method: 'POST',
          data: JSON.stringify(this.badge),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$router.push(`/badges/details/${response.data.name}`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createBadge() })
            return error
          })
    }
  },
  beforeMount: function () {
    this.badge.image.base64Image = require('@/assets/defaultBadge.svg')
  }
}
</script>

<style scoped>

</style>
